import React from 'react';
import './ProgressTracker.css';

const ProgressTracker = ({ points, level, completedChallenges, faithAreas }) => {
  const getNextLevelPoints = () => {
    if (points < 100) return 100;
    if (points < 200) return 200;
    return Math.ceil(points / 100) * 100;
  };

  const progressToNextLevel = ((points % 100) / 100) * 100;

  return (
    <div className="progress-tracker">
      <h2>Your Faith Journey Progress</h2>
      <div className="progress-stats">
        <div className="stat-item">
          <span className="stat-label">Level:</span>
          <span className="stat-value">{level}</span>
        </div>
        <div className="stat-item">
          <span className="stat-label">Points:</span>
          <span className="stat-value">{points}</span>
        </div>
        <div className="stat-item">
          <span className="stat-label">Completed Challenges:</span>
          <span className="stat-value">{completedChallenges}</span>
        </div>
      </div>
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progressToNextLevel}%` }}></div>
      </div>
      <p className="progress-text">Progress to Next Level: {progressToNextLevel.toFixed(1)}%</p>
      <p className="next-level-text">Next level at {getNextLevelPoints()} points</p>
      <div className="faith-areas">
        <h3>Faith Areas</h3>
        {Object.entries(faithAreas).map(([area, value]) => (
          <div key={area} className="faith-area">
            <span className="faith-area-label">{area}:</span>
            <span className="faith-area-value">{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressTracker;