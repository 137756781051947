import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import './Reflections.css';

const Reflections = ({ userId }) => {
  const [reflections, setReflections] = useState([]);
  const [newReflection, setNewReflection] = useState({ title: '', content: '' });
  const [selectedReflection, setSelectedReflection] = useState(null);

  useEffect(() => {
    fetchReflections();
  }, [userId]);

  const fetchReflections = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.REFLECTIONS}/${userId}`);
      setReflections(response.data);
    } catch (error) {
      console.error('Error fetching reflections:', error);
    }
  };

  const handleSubmitReflection = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_ENDPOINTS.REFLECTIONS}/${userId}`, newReflection);
      setNewReflection({ title: '', content: '' });
      fetchReflections();
    } catch (error) {
      console.error('Error submitting reflection:', error);
    }
  };

  const handleReflectionClick = (reflection) => {
    setSelectedReflection(reflection);
  };

  return (
    <div className="reflections-container">
      <h2>Your Reflections</h2>
      <p className="reflections-description">
        Reflections are incorporated into your spiritual direction chat, allowing you to discuss specific reflections in the context of your spiritual journey.
      </p>
      <form onSubmit={handleSubmitReflection} className="new-reflection-form">
        <input
          type="text"
          placeholder="Reflection Title"
          value={newReflection.title}
          onChange={(e) => setNewReflection({ ...newReflection, title: e.target.value })}
          required
        />
        <textarea
          placeholder="Your Reflection"
          value={newReflection.content}
          onChange={(e) => setNewReflection({ ...newReflection, content: e.target.value })}
          required
        ></textarea>
        <button type="submit">Submit Reflection</button>
      </form>
      <div className="reflections-list">
        {reflections.map((reflection) => (
          <div
            key={reflection.id}
            className="reflection-item"
            onClick={() => handleReflectionClick(reflection)}
          >
            <h3>{reflection.title}</h3>
            <p>{reflection.content.substring(0, 100)}...</p>
          </div>
        ))}
      </div>
      {selectedReflection && (
        <div className="reflection-modal">
          <div className="reflection-modal-content">
            <h3>{selectedReflection.title}</h3>
            <p>{selectedReflection.content}</p>
            <button onClick={() => setSelectedReflection(null)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Reflections;