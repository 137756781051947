import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import Research from '../Research/Research';
import Invest from '../Invest/Invest';
import './Rewards.css';

const Rewards = () => {
  const [userId, setUserId] = useState(null);
  const [userPoints, setUserPoints] = useState(0);
  const [activeTab, setActiveTab] = useState('learn');
  const [referralCode, setReferralCode] = useState('');
  const [conversionRate, setConversionRate] = useState(5);
  const [showConvertPopup, setShowConvertPopup] = useState(false);
  const [schwabAccountNumber, setSchwabAccountNumber] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const currentUserId = localStorage.getItem('userId');
    setUserId(currentUserId);

    if (currentUserId) {
      fetchUserPoints(currentUserId);
      fetchReferralCode(currentUserId);
    }
  }, []);

  const fetchUserPoints = async (id) => {
    try {
      const response = await axios.get(`/api/faith_journey/user_points/${id}`);
      setUserPoints(response.data.points);
    } catch (error) {
      console.error('Error fetching user points:', error);
    }
  };

  const fetchReferralCode = async (id) => {
    try {
      const response = await axios.get(`/api/referral_code/${id}`);
      setReferralCode(response.data.code);
    } catch (error) {
      console.error('Error fetching referral code:', error);
    }
  };

  const handleConvertPoints = () => {
    setShowConvertPopup(true);
  };

  const handleSubmitConversion = async () => {
    if (!schwabAccountNumber) {
      alert('Please enter your Schwab account number.');
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axios.post('/api/convert_points', {
        userId,
        points: userPoints,
        schwabAccountNumber
      });

      if (response.data.success) {
        setUserPoints(response.data.remainingPoints);
        setShowConvertPopup(false);
        alert(`Successfully converted points. $${response.data.dollarsEarned} will be transferred to your Schwab account.`);
      } else {
        alert('Failed to convert points. Please try again.');
      }
    } catch (error) {
      console.error('Error converting points:', error);
      alert('An error occurred while converting points. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleShareReferralCode = () => {
    alert(`Your referral code is: ${referralCode}`);
  };

  return (
    <div className="catholic-ai-container">
      <Header />
      <div className="catholic-ai-content">
        <Navbar />
        <div className="catholic-ai-main">
          <section className="catholic-ai-section welcome-section">
            <h1>Catholic AI Rewards</h1>
            <div className="rewards-description">
              <p>Earn points by completing challenges, inviting friends, and investing in Catholic AI. Convert your points to dollars and invest in top-ranked companies aligned with Catholic values.</p>
            </div>
            <div className="points-display">
              <h2>Your Points: {userPoints}</h2>
              <div className="progress-bar">
                <div className="progress" style={{width: `${(userPoints % conversionRate) / conversionRate * 100}%`}}></div>
              </div>
              <p>{conversionRate} points = $5 investment</p>
              <button className="catholic-ai-button" onClick={handleConvertPoints}>Convert Points</button>
            </div>
          </section>
          
          <section className="catholic-ai-section points-and-rewards">
            <div className="points-and-rewards-content">
              <div className="earn-points">
                <h2>How to Earn Points</h2>
                <ul>
                  <li>Complete spiritual challenges: 10 points every 4 weeks</li>
                  <li>Invite friends to use the application: 5 points per active referral</li>
                  <li>Complete goals: 5 points per goal</li>
                  <li>Attend events: 1 point per event</li>
                  <li>Directly invest in Catholic AI: 1 point per $100 invested</li>
                </ul>
              </div>
              <div className="rewards-process">
                <h2>How Rewards Work</h2>
                <ol>
                  <li>Earn points through various activities</li>
                  <li>Convert points to dollars (minimum $5)</li>
                  <li>Link your Schwab brokerage account</li>
                  <li>Invest in top-ranked companies aligned with Catholic values</li>
                  <li>Use the Research tab to explore company rankings and AI information</li>
                  <li>Use the Invest tab to create and execute your optimal portfolio</li>
                </ol>
              </div>
            </div>
          </section>
          
          <div className="catholic-ai-section tab-interface">
            <div className="tab-buttons">
              <button onClick={() => setActiveTab('learn')} className={activeTab === 'learn' ? 'active' : ''}>Research</button>
              <button onClick={() => setActiveTab('invest')} className={activeTab === 'invest' ? 'active' : ''}>Invest</button>
            </div>
            <div className="tab-description">
              {activeTab === 'learn' ? 
                <p>Research top companies and their AI alignment with Catholic values.</p> :
                <p>Create and execute your optimal investment portfolio.</p>
              }
            </div>
            <div className="tab-content">
              {activeTab === 'learn' && <Research />}
              {activeTab === 'invest' && <Invest />}
            </div>
          </div>
          
          <section className="catholic-ai-section invite-friends">
            <h2>Invite Friends</h2>
            <p>Your referral code: <strong>{referralCode}</strong></p>
            <p>Earn 5 points each month when your friend uses the app!</p>
            <button className="catholic-ai-button" onClick={handleShareReferralCode}>Share Referral Code</button>
          </section>
        </div>
      </div>

      {showConvertPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>Convert Points to Investment</h3>
            <p>To convert your points, please enter your Schwab brokerage account number:</p>
            <input 
              type="text" 
              value={schwabAccountNumber} 
              onChange={(e) => setSchwabAccountNumber(e.target.value)}
              placeholder="Schwab Account Number"
            />
            <p className="popup-info">An ACH transfer will be initiated with your reward points. Your account will be updated after the ACH is submitted to Schwab, which usually takes 5 business days before it can be invested.</p>
            <p className="popup-info">If you complete the investment process in the Invest tab and provide authentication, we'll directly invest it for you when the cash becomes available in your Schwab account.</p>
            <div className="popup-buttons">
              <button 
                onClick={handleSubmitConversion} 
                className="catholic-ai-button" 
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
              <button 
                onClick={() => setShowConvertPopup(false)} 
                className="catholic-ai-button secondary"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Rewards;