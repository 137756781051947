import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Chat from './pages/Chat/Chat';
import SignUp from './pages/SignUp/SignUp';
import SignIn from './pages/SignIn/SignIn';
import About from './pages/About/About';
import Authenticate from './pages/Authenticate/Authenticate';
import './App.css';
import ObjectivesChat from './pages/ObjectivesChat/ObjectivesChat';
import Profile from './pages/Profile/Profile';
import Trade from './pages/Trade/Trade';
import BrokerageAccount from './pages/BrokerageAccount/BrokerageAccount';
import { setUserId } from './store/actions/userActions';
import SchwabCallback from './components/SchwabCallback';
import FaithJourney from './pages/FaithJourney/FaithJourney';
import Rewards from './pages/Rewards/Rewards';

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const isAuthorized = useSelector(state => state.user.isAuthorized);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      dispatch(setUserId(userId));
    }
  }, [dispatch]);

  return (
    <Router>
      <div className="app">
        <Navbar />
        <Routes>
          <Route path="/" element={<Chat />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/objectives-chat" element={isAuthenticated ? <ObjectivesChat /> : <SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/about" element={<About />} />
          <Route path="/authenticate" element={<Authenticate />} />
          <Route path="/profile" element={isAuthorized ? <Profile /> : <SignIn />} />
          <Route path="/trade" element={isAuthenticated ? <Trade /> : <SignIn />} />
          <Route path="/brokerage-account" element={isAuthenticated ? <BrokerageAccount /> : <SignIn />} />
          <Route path="/schwab-callback" element={<SchwabCallback />} />
          <Route path="/callbacks" element={<SchwabCallback />} />
          <Route path="/faith-journey" element={<FaithJourney />} />
          <Route path="/rewards" element={<Rewards />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;