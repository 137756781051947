import React, { useState } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import './AssessmentModal.css';

const assessmentQuestions = [
  {
    category: "Sacramental Life",
    questions: [
      "How often do you currently attend Sunday Mass?",
      "Do you attend daily Mass? If so, how frequently?",
      "How often do you go to Confession?",
      "How frequently do you spend time in Adoration?",
    ]
  },
  {
    category: "Prayer and Scripture",
    questions: [
      "How often do you read Scripture?",
      "Do you have a daily prayer routine? If so, what does it involve?",
      "How frequently do you pray the Rosary?",
    ]
  },
  {
    category: "Community and Evangelization",
    questions: [
      "How often do you attend Catholic social events?",
      "Have you ever organized a Catholic event (prayer, social, or study)?",
      "How frequently do you invite others to attend Catholic events with you?",
      "Do you regularly discuss your faith with friends or invite them to faith-related activities?",
    ]
  }
];

const AssessmentModal = ({ onSubmit, onClose, userId }) => {
  const [responses, setResponses] = useState({});
  const [step, setStep] = useState('questions');
  const [assessmentResult, setAssessmentResult] = useState(null);
  const [selectedChallenges, setSelectedChallenges] = useState({});
  const [customGoals, setCustomGoals] = useState([]);
  const [newCustomGoal, setNewCustomGoal] = useState({
    description: '',
    category: 'Custom',
    weekly_prompts: ['', '', '', '']
  });

  const handleInputChange = (category, questionIndex, value) => {
    setResponses(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [questionIndex]: value
      }
    }));
  };

  const handleSubmitResponses = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting assessment responses for user:', userId);
      const result = await axios.post(`${API_ENDPOINTS.SUBMIT_ASSESSMENT_RESPONSES}/${userId}`, { responses });
      console.log('Assessment submission result:', result.data);
      setAssessmentResult(result.data);
      setStep('selectChallenges');
    } catch (error) {
      console.error('Error submitting assessment:', error.response ? error.response.data : error.message);
      alert('There was an error submitting your assessment. Please try again.');
    }
  };

  const handleSelectChallenge = (category, challenge) => {
    if (Object.keys(selectedChallenges).length + customGoals.length >= 3) {
      alert("You can only have a maximum of 3 active challenges/goals.");
      return;
    }
    setSelectedChallenges(prev => ({
      ...prev,
      [category]: challenge
    }));
  };

  const handleCustomGoalChange = (field, value) => {
    setNewCustomGoal(prev => ({
      ...prev,
      [field]: field === 'weekly_prompts' ? value : value
    }));
  };

  const handleAddCustomGoal = () => {
    if (customGoals.length + Object.keys(selectedChallenges).length >= 3) {
      alert("You can only have a maximum of 3 active challenges/goals.");
      return;
    }
    setCustomGoals([...customGoals, newCustomGoal]);
    setNewCustomGoal({
      description: '',
      category: 'Custom',
      weekly_prompts: ['', '', '', '']
    });
  };

  const handleSubmitChallenges = async () => {
    try {
      console.log('Submitting selected challenges for user:', userId);
      const formattedSelectedChallenges = Object.values(selectedChallenges).map(challenge => ({
        category: challenge.category,
        description: challenge.description,
        weekly_prompts: challenge.weekly_prompts
      }));

      const formattedCustomGoals = customGoals.map(goal => ({
        category: 'Custom',
        description: goal.description,
        weekly_prompts: goal.weekly_prompts
      }));

      const challengesToSubmit = [...formattedSelectedChallenges, ...formattedCustomGoals];

      const challengeResult = await axios.post(`${API_ENDPOINTS.SUBMIT_SELECTED_CHALLENGES}/${userId}`, {
        selectedChallenges: challengesToSubmit
      });
      console.log('Challenge submission result:', challengeResult.data);

      // Submit the assessment using the new endpoint
      const assessmentResult = await axios.post(`${API_ENDPOINTS.SUBMIT_ASSESSMENT}/${userId}`, {
        levels: challengeResult.data.levels,
        progress: challengeResult.data.progress
      });
      console.log('Assessment submission result:', assessmentResult.data);

      onSubmit(assessmentResult.data);
      onClose();
    } catch (error) {
      console.error('Error submitting challenges and assessment:', error.response ? error.response.data : error.message);
      alert('There was an error submitting your challenges and assessment. Please try again.');
    }
  };

  const renderQuestions = () => (
    <form onSubmit={handleSubmitResponses}>
      {assessmentQuestions.map((categoryData, categoryIndex) => (
        <div key={categoryIndex} className="assessment-category">
          <h3>{categoryData.category}</h3>
          {categoryData.questions.map((question, questionIndex) => (
            <div key={questionIndex} className="assessment-question">
              <label>{question}</label>
              <textarea
                value={responses[categoryData.category]?.[questionIndex] || ''}
                onChange={(e) => handleInputChange(categoryData.category, questionIndex, e.target.value)}
                required
              />
            </div>
          ))}
        </div>
      ))}
      <button type="submit" className="submit-button">Submit Responses</button>
    </form>
  );

  const renderChallengeSelection = () => (
    <div className="challenge-selection">
      <h2>Select Your Challenges</h2>
      <p className="challenge-instructions">Choose up to 3 goals in total: you can select from the suggested challenges or create custom goals.</p>
      {assessmentResult && Object.entries(assessmentResult).map(([category, data]) => (
        <div key={category} className="category-challenges">
          <h3>{category} - Level: {data.level}</h3>
          <div className="challenge-options">
            {data.challenges.map((challenge, index) => (
              <div key={index} className="challenge-option">
                <input
                  type="checkbox"
                  id={`${category}-${index}`}
                  checked={selectedChallenges[category]?.description === challenge.description}
                  onChange={() => handleSelectChallenge(category, challenge)}
                />
                <label htmlFor={`${category}-${index}`}>
                  {challenge.description}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="custom-goals-section">
        <h3>Add Custom Goal</h3>
        <p className="custom-goal-instructions">Use custom goals to incorporate specific professional or personal objectives, such as 'praying for colleagues at work' or 'helping a friend with one of their personal challenges'.</p>
        <input
          type="text"
          value={newCustomGoal.description}
          onChange={(e) => handleCustomGoalChange('description', e.target.value)}
          placeholder="Goal description"
          className="custom-goal-input"
        />
        {newCustomGoal.weekly_prompts.map((prompt, index) => (
          <input
            key={index}
            type="text"
            value={prompt}
            onChange={(e) => {
              const newPrompts = [...newCustomGoal.weekly_prompts];
              newPrompts[index] = e.target.value;
              handleCustomGoalChange('weekly_prompts', newPrompts);
            }}
            placeholder={`Week ${index + 1} prompt`}
            className="custom-goal-input"
          />
        ))}
        <button onClick={handleAddCustomGoal} className="add-custom-goal-button">Add Custom Goal</button>
      </div>
      <div className="selected-goals">
        <h4>Selected Goals:</h4>
        {Object.entries(selectedChallenges).map(([category, challenge]) => (
          <div key={category} className="selected-goal">{category}: {challenge.description}</div>
        ))}
        {customGoals.map((goal, index) => (
          <div key={index} className="selected-goal">Custom: {goal.description}</div>
        ))}
      </div>
      <button onClick={handleSubmitChallenges} className="submit-button">Submit Selected Challenges and Goals</button>
    </div>
  );

  return (
    <div className="assessment-modal">
      <div className="assessment-modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>Faith Journey Assessment</h2>
        {step === 'questions' ? renderQuestions() : renderChallengeSelection()}
      </div>
    </div>
  );
};

export default AssessmentModal;